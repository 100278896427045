import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import classes from "./Layout.module.css";
const Layout = (props) => {
  return (
    <div className={classes.Layout}>
      <Header translateBtn={props.translateBtn} classes={classes} />
      <main>{props.children}</main>
      <Footer classes={classes} />
    </div>
  );
};
export default Layout;
