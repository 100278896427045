import { motion } from "framer-motion";
import { useEffect } from "react";
import classes from "./modal.module.css";

const Modal = (props) => {
  useEffect(() => {
    document.body.style = "overflow: hidden";
    return () => {
      document.body.removeAttribute("style");
    };
  }, []);

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 100 },
  };

  const fade = {
    type: "tween",
    default: { duration: 0.3 },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classes.ModalBackDrop}
    >
      <div className={classes.Clickable} onClick={props.close} />
      <motion.div
        className={classes.Modal}
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={variants}
        transition={fade}
      >
        <span
          className="absolute top-4 right-4 cursor-pointer"
          onClick={props.close}
        >
          X
        </span>
        <div className="border-b pb-4">
          <h1 className="text-center">{props.title}</h1>
        </div>
        <div className={classes.body}>{props.children}</div>
        <div>
          <div className={classes.ActionButtons}>
            {props.buttons}
            {props.buttonText && (
              <button
                className={`btn ${props.next ? "btn-success" : "btn-primary"}`}
                onClick={props.next || props.close}
              >
                {props.buttonText}
              </button>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Modal;
