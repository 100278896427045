import { useTranslation } from "react-i18next";
import Container from "../../container/Container";
import Social from "./Social";
import Contact from "./Contact";

const Footer = ({ classes }) => {
  const { t } = useTranslation("common");

  return (
    <footer className={classes.Footer}>
      <Container style={{ textAlign: "center" }}>
        <div className={classes.grid}>
          <div className={classes.left}>
            <Contact />
          </div>
          <div className={classes.copyright}>{t("footer.copyright")}</div>
          <div className={classes.right}>
            <Social />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
