import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import classes from "../Layout.module.css";

const Contact = () => {
  const { t } = useTranslation("common");
  return (
    <ul>
      <li className={classes.Mail}>
        <a href={`mailto:${t("contact.email")}`} title="E-mail">
          <FontAwesomeIcon icon={faEnvelope} /> {t("contact.email")}
        </a>
      </li>
      {t("contact.tel.url").length > 0 && (
        <li className={classes.Phone}>
          <a href={`tel:${t("contact.tel.url")}`} title="Phone">
            <FontAwesomeIcon icon={faPhone} /> {t("contact.tel.text")}
          </a>
        </li>
      )}
    </ul>
  );
};

export default Contact;
