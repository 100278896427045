import Container from "../../container/Container";
import LanguageButton from "./LanguageButton";
import Logo from "./Logo";
const Header = ({ classes }) => {
  return (
    <header className={classes.Header}>
      <Container>
        <div className={classes.HeaderContent}>
          <Logo classes={classes.Logo} />
          <LanguageButton />
        </div>
      </Container>
    </header>
  );
};

export default Header;
