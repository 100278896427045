import { useTranslation } from "react-i18next";
import Slider from "../../util/Slider";

const LanguageButton = (props) => {
  const { t, i18n } = useTranslation("common");

  const changeLanguage = () => {
    const toLang = t("params.other-language");
    localStorage.setItem("lang", toLang);
    i18n.changeLanguage(toLang);
  };

  return (
    <>
      <Slider onClick={changeLanguage} isActive={i18n.language === "sv"} />
    </>
  );
};

export default LanguageButton;
