import { useTranslation } from "react-i18next";

const TermsCheckBox = (props) => {
  const { t } = useTranslation("common");
  return (
    <label htmlFor="tos" className="flex items-center">
      <input
        type="checkbox"
        onChange={props.toggleTOS}
        checked={props.TOS}
        id="tos"
      />
      <span className="larger mx-2"> {t("services.terms.checkbox")} </span>
      <button className="btn btn-secondary" onClick={props.onClick}>
        {t("services.terms.button")}
      </button>
    </label>
  );
};
export default TermsCheckBox;
