import Home from "./pages/Home";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Layout from "./components/layouts/Layout/Layout";

function App() {
  return (
    <Router>
      <Layout>
        <Route path="/:link?">
          <Home />
        </Route>
      </Layout>
    </Router>
  );
}

export default App;
