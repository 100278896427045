import { useTranslation } from "react-i18next";
import Container from "../layouts/container/Container";
import Discount from "./Discount/Discount";
import PersonalProcess from "./PersonalProcess";
import ServiceCard from "./ServiceCard";
import TermsCheckBox from "./tos/TermsCheckBox";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TosModal from "../modals/tos-modal/TosModal";
import { AnimatePresence } from "framer-motion";
import { openPopupWidget } from "react-calendly";
import ValidateDiscount from "../layouts/util/validateDiscount";
import ContactModal from "../modals/contact-modal/ContactModal";

const DISCOUNT_FILTER = ["new", "old"];
const HELP_NAMES = ["hjalp", "help"];

const Services = () => {
  const getSessionItem = (item) => sessionStorage.getItem(item) || false;

  const initTOS = JSON.parse(sessionStorage.getItem("terms")) || false;
  const initDiscount = getSessionItem("discount");

  const [ContactModalShown, setContactModalShown] = useState(false);
  const [TOSModalShown, setTOSModalShown] = useState();
  const [services, setServices] = useState(null);

  const [termsAndConditions, setTermsAndConditions] = useState(initTOS);
  const [discount, setDiscount] = useState(initDiscount);

  const { t, i18n } = useTranslation("common");

  const history = useHistory();
  const { link } = useParams();

  const toggleTermsAndConditions = () => {
    setTermsAndConditions(!termsAndConditions);
    sessionStorage.setItem("terms", !termsAndConditions);
  };

  const toggleTOSModal = () => {
    setTOSModalShown(!TOSModalShown);
  };
  const closeTOSModal = () => {
    setTOSModalShown(false);
    history.replace("/");
  };

  const acceptTOS = () => {
    setTermsAndConditions(true);
    sessionStorage.setItem("terms", true);
    setTOSModalShown(false);
  };

  useEffect(() => {
    const addEventListeners = () => {
      const array = [".calendly-close-overlay", ".calendly-popup-close"];
      array.forEach((el) => {
        document.removeEventListener("click", () => {
          history.replace("/");
        });
        document.querySelector(el)?.addEventListener("click", () => {
          history.replace("/");
        });
      });
    };

    if (link) {
      const suffix = ValidateDiscount(discount, i18n.language)
        ? `-${sessionStorage.getItem("discount")}`
        : "";

      const url = `https://calendly.com/vheintuition/${link}${
        link !== "help" && link !== "hjalp" ? suffix : ""
      }`;
      if (!termsAndConditions) setTOSModalShown(true);
      else {
        setTimeout(() => {
          openPopupWidget({ url });
          addEventListeners();
          window.onload = () => addEventListeners();
        }, 500);
      }
    }

    return () => {
      document.body.removeAttribute("style");
    };
  }, [discount, history, i18n.language, link, termsAndConditions]);

  useEffect(() => {
    setServices(
      Object.entries(t("services.available", { returnObjects: true }))
        .filter(([, el]) => {
          if (el.name === "") return null;
          if (
            DISCOUNT_FILTER.includes(discount) &&
            HELP_NAMES.includes(el.link)
          )
            return null;
          return el;
        })
        .map(([key, el]) => {
          return (
            <ServiceCard
              loadService={(link) => history.replace(`/${link}`)}
              discount={discount}
              key={key}
              {...el}
            />
          );
        })
    );
  }, [discount, history, link, t]);

  return (
    <Container>
      <h1 className="pb-10">{t("services.title")}</h1>
      <div className="flex flex-col-reverse lg:flex-row lg:justify-between">
        <TermsCheckBox
          TOS={termsAndConditions}
          toggleTOS={toggleTermsAndConditions}
          onClick={toggleTOSModal}
        />
        <Discount discount={discount} setDiscount={setDiscount} />
      </div>
      <ul className="services">
        {services}
        {!DISCOUNT_FILTER.includes(discount) && <PersonalProcess />}
      </ul>
      <div className="flex flex-col items-center my-20">
        <div>{t("services.timeslot.text")}</div>
        <div>
          <button
            className="btn btn-success"
            onClick={() => setContactModalShown(true)}
          >
            {t("common:services.timeslot.button")}
          </button>
        </div>
      </div>
      <AnimatePresence>
        {TOSModalShown && <TosModal close={closeTOSModal} accept={acceptTOS} />}
      </AnimatePresence>
      <AnimatePresence>
        {ContactModalShown && (
          <ContactModal closeModal={() => setContactModalShown(false)} />
        )}
      </AnimatePresence>
    </Container>
  );
};

export default Services;
