const DiscountConverter = (price, validDiscount = true) => {
  const discount = sessionStorage.getItem("discount");
  if (!validDiscount) return generatePriceAndCurrency(price);
  switch (discount) {
    case "student":
      return generatePriceAndCurrency(price - 20);
    case "foralder":
      return generatePriceAndCurrency(
        (Math.round(price * 0.7 * 100) / 100).toFixed(2)
      );
    case "new":
      return generatePriceAndCurrency(30);
    case "old":
      return generatePriceAndCurrency(17, "USD");
    default:
      return generatePriceAndCurrency(price);
  }
};

export default DiscountConverter;

function generatePriceAndCurrency(price, currency = "EUR") {
  return { price, currency };
}
