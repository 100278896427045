import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import CurrencyConverter from "../../utils/currencyConverter";
import DiscountConverter from "../../utils/discountConverter";
import currencyToSymbol from "currency-symbol-map";
import ValidateDiscount from "../layouts/util/validateDiscount";

import { motion, useAnimation } from "framer-motion";

const ServiceCard = (props) => {
  const { name, link, preview, duration, price, loadService, discount } = props;

  const { t, i18n } = useTranslation("common");

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const [toCurrency, setToCurrency] = useState("");
  const [priceInfo, setPriceInfo] = useState();

  useEffect(() => {
    const validDiscount = ValidateDiscount(discount, i18n.language);
    const discountPrice = DiscountConverter(price, validDiscount);
    const currencyToConvert = t("services.convertToCurrency");

    setPriceInfo(discountPrice);

    if (currencyToConvert.length)
      CurrencyConverter(
        discountPrice.currency,
        currencyToConvert,
        discountPrice.price
      ).then((res) => setToCurrency(res));

    return setToCurrency("");
  }, [discount, i18n.language, price, t]);

  useEffect(() => {
    if (inView) controls.start("visible");
    else controls.start("hidden");
  }, [controls, inView]);

  if (!priceInfo) {
    return <div>Loading...</div>;
  }

  const hasPrice =
    priceInfo.price > 0
      ? `${currencyToSymbol(priceInfo.currency)}${
          priceInfo.price
        } ${toCurrency}`
      : t("services.free");

  return (
    <motion.li
      className="service-card"
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.4 }}
      variants={{
        visible: { opacity: 1, transform: "scale(1)" },
        hidden: { opacity: 0, transform: "scale(.9)" },
      }}
    >
      <div>
        <h4>{name}</h4>
        <div className="info">{`${hasPrice} | ${duration}min`}</div>
        <p>{preview}</p>
      </div>
      <div className="footer">
        <button onClick={() => loadService(link)} className="btn-primary">
          {t("services.button")}
        </button>
      </div>
    </motion.li>
  );
};

export default ServiceCard;
