const ValidateDiscount = (discount, lang) => {
  if (!discount) return false;
  if (lang === "sv") return true;
  switch (discount) {
    case "student":
      return false;
    case "foralder":
      return false;
    default:
      return true;
  }
};

export default ValidateDiscount;
