import { Trans, useTranslation } from "react-i18next";
import Separator from "../../layouts/util/Separator";
import Modal from "../modal";

const TosModal = (props) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      title={t("services.modals.terms.modal.title")}
      close={props.close}
      next={props.accept}
      buttonText={t("services.modals.terms.buttons.accept")}
    >
      <Separator className="m-10" />
      <h1>{t("services.modals.terms.info.title")}</h1>
      <Trans i18nKey="">{t("services.modals.terms.info.content")}</Trans>
      <Separator className="m-20" />
      <h1>{t("services.modals.terms.title")}</h1>
      <Trans i18nKey="tos-list">
        <ul>
          <li>{t("services.modals.terms.list.age")}</li>
          <li>{t("services.modals.terms.list.disclaimer.medical")}</li>
          <li>{t("services.modals.terms.list.disclaimer.cure")}</li>
          <li>{t("services.modals.terms.list.remote")}</li>
        </ul>
      </Trans>
    </Modal>
  );
};
export default TosModal;
