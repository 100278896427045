import { Form, Formik, Field } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import * as Yup from "yup";
import Modal from "../modal";

import classes from "./ContactModal.module.css";

const ContactModal = ({ closeModal }) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const sendMessage = async (values) => {
    const data = new FormData();
    Object.entries(values).forEach(([key, el]) => data.append(key, el));
    setLoading(true);
    const res = await fetch("https://vheintuition.com/api/send_email.php", {
      method: "POST",
      body: data,
    });
    const json = await res.json();
    if (json.status === 200) {
      setLoading(false);
      setMessage(t("contactForm.status.success.text"));
      setTimeout(() => {
        setMessage(null);
        closeModal();
      }, 5000);
    }
    console.log(json);
  };

  const phoneRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const contactSchema = Yup.object().shape(
    {
      name: Yup.string().required(t("contactForm.fields.name.error")),

      email: Yup.string()
        .email("Must be a valid E-mail")
        .ensure()
        .when("phone", {
          is: "",
          then: Yup.string().required(t("contactForm.fields.email.error")),
        }),

      phone: Yup.string()
        .ensure()
        .when("email", {
          is: "",
          then: Yup.string()
            .required(t("contactForm.fields.phone.error"))
            .matches(phoneRegex, "Invalid Phone Number"),
        }),

      message: Yup.string().required(t("contactForm.fields.message.error")),
    },
    [["phone", "email"]]
  );

  if (loading)
    return (
      <Modal close={closeModal} title="Sending message...">
        <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
          <Loader type="Circles" color="#de7b90" />
        </div>
      </Modal>
    );

  if (message)
    return (
      <Modal close={closeModal} title="Message Sent" buttonText="Ok">
        <message
          style={{
            display: "grid",
            placeContent: "center",
            height: "100%",
          }}
        >
          <h1>{message}</h1>
        </message>
      </Modal>
    );

  return (
    <Modal close={closeModal} title={t("contactForm.title")}>
      <Formik
        initialValues={{
          name: "",
          phone: "",
          email: "",
          message: "",
        }}
        validationSchema={contactSchema}
        onSubmit={(values) => sendMessage(values)}
      >
        {({ errors, touched }) => (
          <Form className={classes.Form}>
            <div></div>
            <div>
              <div className="mb-10 relative">
                <Field
                  type="text"
                  id="name"
                  style={{ width: "100%" }}
                  name="name"
                  placeholder=" "
                />
                <div style={{ color: "red", height: "1ch" }}>
                  {touched.name && errors.name}
                </div>
                <label
                  htmlFor="name"
                  class="absolute top-2 left-2 text-gray-500"
                >
                  {t("contactForm.fields.name.label")}
                </label>
              </div>
              <div className="mb-10 relative">
                <Field
                  type="text"
                  id="email"
                  style={{ width: "100%" }}
                  name="email"
                  placeholder=" "
                />
                <div style={{ color: "red", height: "1ch" }}>
                  {touched.email && errors.email}
                </div>
                <label
                  htmlFor="email"
                  class="absolute top-2 left-2 text-gray-500"
                >
                  {t("contactForm.fields.email.label")}
                </label>
              </div>
              {t("contactForm.fields.phone.label") && (
                <div className="mb-10 relative">
                  <Field
                    type="tel"
                    id="tel"
                    className="input"
                    style={{ width: "100%" }}
                    name="phone"
                    placeholder=" "
                  />

                  <div style={{ color: "red", height: "1ch" }}>
                    {touched.phone && errors.phone}
                  </div>
                  <label
                    htmlFor="tel"
                    class="absolute top-2 left-2 text-gray-500"
                  >
                    {t("contactForm.fields.phone.label")}
                  </label>
                </div>
              )}
              <div className="mb-10 relative">
                <Field
                  as="textarea"
                  id="message"
                  className="input"
                  style={{ width: "100%" }}
                  name="message"
                  placeholder=" "
                />
                <div style={{ color: "red", height: "1ch" }}>
                  {touched.message && errors.message}
                </div>
                <label
                  htmlFor="message"
                  class="absolute top-2 left-2 text-gray-500"
                >
                  {t("contactForm.fields.message.label")}
                </label>
              </div>
            </div>
            <div
              className="mb-10 flex justify-center"
              style={{ textAlign: "center" }}
            >
              <button type="submit" className="btn btn-success mx-2">
                {t("contactForm.buttons.send")}
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-primary mx-2"
              >
                {t("contactForm.buttons.cancel")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ContactModal;
