import classes from "./container.module.css";

const Container = ({ children, style, className }) => {
  return (
    <section className={className}>
      <div className={classes.container} style={style}>
        {children}
      </div>
    </section>
  );
};

export default Container;
