import { useTranslation } from "react-i18next";
import Container from "../layouts/container/Container";

import classes from "./Welcome.module.css";
const Welcome = (props) => {
  const { t } = useTranslation("common");

  return (
    <Container className={classes.Welcome}>
      <div className={classes.WelcomeCard}>
        <div className={classes.WelcomeText}>
          <h1>{t("welcome.title")}</h1>
          <h2>{t("welcome.slogan")}</h2>
        </div>
        <button className="btn-primary btn-xl" onClick={props.scrollTo}>
          {t("welcome.button")}
        </button>
      </div>
    </Container>
  );
};

export default Welcome;
