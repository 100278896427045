import { NavLink } from "react-router-dom";

const Logo = ({ classes }) => (
  <NavLink to="/" className={classes}>
    <img
      className="h-14"
      src="/images/both_loggo.png"
      alt="Vhe Intuition Logo"
    />
    <h2 className="text-2xl">Vhe Intuition</h2>
  </NavLink>
);

export default Logo;
