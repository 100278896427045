import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./translations/en/common.json";
import common_sv from "./translations/sv/common.json";

export default function i18nextInit() {
  const { language, userLanguage } = navigator;
  const localLang = localStorage.getItem("lang");
  const lng = (localLang || language || userLanguage || "en").replace(
    /-.*/i,
    ""
  );

  const interpolation = {
    escapeValue: false,
  };
  const supportedLngs = ["en", "sv"];
  const resources = {
    en: { common: common_en },
    sv: { common: common_sv },
  };

  i18next.use(initReactI18next);
  i18next.init({
    interpolation,
    lng,
    supportedLngs,
    resources,
  });
}
