import classes from "../Layout.module.css";
import {
  faDiscord,
  faPatreon,
  faTwitch,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Social = () => (
  <ul>
    <li className={classes.Twitch}>
      <a
        href="https://twitch.tv/vheintuition"
        target="_blank"
        rel="noreferrer"
        title="Twitch"
      >
        <FontAwesomeIcon icon={faTwitch} /> Twitch
      </a>
    </li>
    <li className={classes.Discord}>
      <a
        href="https://discord.gg/fs2NnW2"
        target="_blank"
        rel="noreferrer"
        title="Discord"
      >
        <FontAwesomeIcon icon={faDiscord} /> Discord
      </a>
    </li>
    <li className={classes.Youtube}>
      <a
        href="https://www.youtube.com/channel/UCgb74xSpDxDmnA1lVY0lTIg"
        target="_blank"
        rel="noreferrer"
        title="Youtube"
      >
        <FontAwesomeIcon icon={faYoutube} /> Youtube
      </a>
    </li>
  </ul>
);
export default Social;
