import { Trans, useTranslation } from "react-i18next";

const PersonalProcess = (props) => {
  const { t } = useTranslation("common");

  return (
    <div className={props.className || null}>
      <h3>{t("services.personal-process.title")}</h3>
      <p>
        <Trans>{t("services.personal-process.info")}</Trans>
      </p>
      <p>
        {" "}
        {t("services.personal-process.howto.step1")}
        <br />
        {t("services.personal-process.howto.step2")}
      </p>
    </div>
  );
};

export default PersonalProcess;
