const DiscountTypes = (typ) => {
  switch (typ) {
    case "student":
      return "student";
    case "foralder":
      return "foralder";
    case "prenOld":
      return "old";
    case "prenNew":
      return "new";
    default:
      return;
  }
};

export default DiscountTypes;
