import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import ValidateDiscount from "../../layouts/util/validateDiscount";
import DiscountTypes from "./DiscountTypes";
const Discount = ({ discount, setDiscount }) => {
  const { t, i18n } = useTranslation("common");
  const [error, setError] = useState(false);
  const discountRef = useRef();

  const activateDiscount = (event) => {
    event.preventDefault();

    const rabattkod = discountRef.current.value.toLowerCase();
    const request = $.ajax({
      url: "/api/rabatt.php",
      type: "post",
      data: { rabattkod },
    });
    request.done((data) => {
      const res = JSON.parse(data);
      if (!ValidateDiscount(res.typ, i18n.language)) return setError(true);
      setDiscount(DiscountTypes(res.typ));
      setError(false);
    });
  };

  if (discount && ValidateDiscount(discount, i18n.language)) {
    sessionStorage.setItem("discount", discount);
    return (
      <span className="text-green-600">
        {t(`services.discount.active.${discount}`)}
      </span>
    );
  }

  return (
    <form className="my-6" onSubmit={activateDiscount}>
      <span className="relative">
        <input
          ref={discountRef}
          id="discount"
          className="mr-4"
          type="text"
          placeholder=" "
        />
        <label htmlFor="discount" class="absolute top-0 left-2 text-gray-500">
          {t("services.discount.placeholder")}
        </label>
      </span>
      <input
        type="submit"
        className="btn-success"
        value={t("services.discount.button")}
      />
      {error && (
        <div style={{ color: "red" }}>{t("services.discount.invalid")}</div>
      )}
    </form>
  );
};

export default Discount;
