import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Slider.module.css";

const Slider = (props) => (
  // <label className={classes.switch}>
  //   <input
  //     type="checkbox"
  //     checked={props.isActive}
  //     readOnly
  //     onClick={props.onClick}
  //   />
  //   <i className={[classes.slider, classes.round].join(" ")}>
  //     <FontAwesomeIcon icon={faGlobeEurope}></FontAwesomeIcon>
  //   </i>
  // </label>

  <span className={classes.SliderContainer}>
    <span className={classes.SliderMarginRight}>English</span>
    <div className={classes.SliderRail} onClick={props.onClick}>
      <div
        className={classes.SliderIconHolder}
        style={{
          right: props.isActive ? ".8ch" : "calc(100% - .8ch)",
        }}
      >
        <FontAwesomeIcon icon={faGlobeEurope} className={classes.SliderIcon} />
      </div>
    </div>
    <span className={classes.SliderMarginLeft}>Svenska</span>
  </span>
);
export default Slider;
