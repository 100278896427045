import { useRef } from "react";
import Separator from "../../components/layouts/util/Separator";
import ContactModal from "../../components/modals/contact-modal/ContactModal";
import Services from "../../components/services/Services";
import Video from "../../components/video/Video";
import Welcome from "../../components/welcome/Welcome";

const Home = () => {
  const scrollRef = useRef();
  const scrollToRef = () => {
    scrollRef.current.scrollIntoView();
  };
  return (
    <>
      <Welcome scrollTo={scrollToRef} />
      <Separator />
      <span ref={scrollRef} />
      <Services />
    </>
  );
};

export default Home;
